import { render, staticRenderFns } from "./ConvocationList.vue?vue&type=template&id=42c3fd62&scoped=true&ref=adminUser&"
import script from "./ConvocationList.vue?vue&type=script&lang=js&"
export * from "./ConvocationList.vue?vue&type=script&lang=js&"
import style0 from "./ConvocationList.vue?vue&type=style&index=0&id=42c3fd62&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42c3fd62",
  null
  
)

export default component.exports